import Contact from '../templates/Contact';
import { PageWrapper } from '../components';

export default PageWrapper(
  Contact,
  'We.Dash | Dashboards De Marketing, Vendas e Growth',
  'https://wedash.digital/contato',
  'Todas Campanhas De Marketing e Vendas Em Única Plataforma Com Dashboards Intuitivos. We.Dash Te Ajuda a Tomar As Melhores Decisões Nos Negócios. Comece Já!',
  'wedash-by-bowe',
);
