import scrollTo from 'gatsby-plugin-smoothscroll';
import AGILITY from '../../assets/icon/agilityIcon.svg';
import COMUNITY from '../../assets/icon/comunityIcon.svg';
import Facebook from '../../assets/icon/facebookIcon.svg';
import Instagram from '../../assets/icon/instagramIcon.svg';
import INTEGRATION from '../../assets/icon/integrationIcon.svg';
import Linkedin from '../../assets/icon/linkedinIcon.svg';
import OBJECTIVE_DESIGN from '../../assets/icon/objectiveDesignIcon.svg';
import ADS_LOGO from '../../assets/img/adsLogo.webp';
import FACEBOOK_LOGO from '../../assets/img/facebookLogo.webp';
import HUBSPOT_LOGO from '../../assets/img/hubspotLogo.svg';
import INSTAGRAM_LOGO from '../../assets/img/instagramLogo.webp';
import LINKEDIN_LOGO from '../../assets/img/linkedinLogo.webp';
import MARKETO_LOGO from '../../assets/img/marketoLogo.webp';
import PIPEDRIVE_LOGO from '../../assets/img/pipedriveLogo.svg';
import PLOOMES_LOGO from '../../assets/img/ploomesLogo.webp';
import RDSTATION_LOGO from '../../assets/img/rdstationLogo.svg';
import ZOHO_LOGO from '../../assets/img/zohoLogo.webp';

export const headerOptions = [
  {
    label: 'Sobre',
    pathname: '/',
    value: 'sobre',
    redirect: false,
  },
  {
    label: 'Produto',
    pathname: '/',
    value: 'produto',
    redirect: false,
  },
  {
    label: 'Diferenciais',
    pathname: '/',
    value: 'diferenciais',
    redirect: false,
  },
  {
    label: 'Planos',
    pathname: '/',
    value: 'planos',
    redirect: false,
  },
  { label: 'FAQ', pathname: '/faq/', redirect: true },
];

export const integrationToolsProp = [
  {
    imgSrc: RDSTATION_LOGO,
    imgAlt: '',
    redirect: 'https://www.rdstation.com/',
  },
  {
    imgSrc: PIPEDRIVE_LOGO,
    imgAlt: '',
    redirect: 'https://www.pipedrive.com/pt/',
  },
  {
    imgSrc: INSTAGRAM_LOGO,
    imgAlt: '',
    redirect: 'https://www.instagram.com/',
  },
  {
    imgSrc: MARKETO_LOGO,
    imgAlt: '',
    redirect: 'https://www.marketo.com/',
  },
  {
    imgSrc: PLOOMES_LOGO,
    imgAlt: '',
    redirect: 'https://www.ploomes.com/',
  },
  {
    imgSrc: FACEBOOK_LOGO,
    imgAlt: '',
    redirect: 'https://www.facebook.com/',
  },
  {
    imgSrc: ADS_LOGO,
    imgAlt: '',
    redirect:
      'https://ads.google.com/intl/pt-BR_br/getstarted/?subid=br-pt-ha-awa-bk-c-cor!o3~EAIaIQobChMI08rO0Zvv7wIVTAaRCh2kWQ2TEAAYASAAEgL4b_D_BwE~84865307024~kwd-94527731~6500862360~428255123406&gclid=EAIaIQobChMI08rO0Zvv7wIVTAaRCh2kWQ2TEAAYASAAEgL4b_D_BwE&gclsrc=aw.ds',
  },
  {
    imgSrc: HUBSPOT_LOGO,
    imgAlt: '',
    redirect: 'https://br.hubspot.com/',
  },
  {
    imgSrc: ZOHO_LOGO,
    imgAlt: '',
    redirect: 'https://www.zoho.com/pt-br/',
  },
  {
    imgSrc: LINKEDIN_LOGO,
    imgAlt: '',
    redirect: 'https://www.linkedin.com/',
  },
];
export const knowSessionDifferentialsProps = [
  {
    imgSrc: INTEGRATION,
    imgAlt:
      'Imagens vazadas, mostradas apenas com contorno, sendo estas de um celular, um notebook, um monitor e um servidor, cada um em cada lado, formando um quadrado e em seu centro há a figura de uma nuvem com conectivos ligando ela às outras imagens, sendo estas o celular, notebook e etc.',
    title: 'Integração',
    context:
      'Integração simplificada e com tecnologia para reunir todas plataformas em um único dashboard.',
  },
  {
    imgSrc: OBJECTIVE_DESIGN,
    imgAlt:
      'Imagens vazadas sendo um quadro com estatísticas de gráficos em quadrados, demonstrando aumentos e quedas, e em frente do quadro na extremidade direita superior temos um novo gráfico de linhas demonstrando grandes quedas e grandes subidas, ainda compondo esta imagem, tempos no canto direito inferior, logo abaixo do gráfico de linhas um novo gráfico mostrado também a frente, sendo este um gráfico de pizza, ou seja um gráfico circular que suas partes indivíduais demonstram porcentagens de alguma estatística',
    title: 'Design Objetivo',
    context:
      'O We.Dash leva clareza às companhias sobre as decisões que devem ser tomadas nas áreas de marketing e vendas, apresentando indicadores como CPL, CPLQ, CTR, ROI, CPV, taxas de conversões por lead qualificado e muito mais.',
  },
  {
    imgSrc: AGILITY,
    imgAlt:
      'Imagem vazada formada por uma seta que começa na parte inferior e faz uma volta circular, formando parte de engrenagens com um relógio no centro do círculo, e encerando a volta voltando a se tornar uma seta, simbolo voltado para demonstrar agilidade, visto a junção do simbolo de uma seta fazendo uma volta para cima e continuando sua continuidade com uma engrenagem e relógio.',
    title: 'Agilidade',
    context:
      'Com uma implantação simplificada, em menos de 3 horas o We.Dash possibilita uma visualização ampla dos principais indicadores para o crescimento do seu negócio.',
  },
  {
    imgSrc: COMUNITY,
    imgAlt:
      'Imagens vazadas de retângulos com uma ponta inferor centralizada indicando balões de conversa, sendo então três balões alinhados da seguinte forma, um abaixo, e dentro deste avendo a circuferencia de três corpos minimamente traçados, indicando pessoas. Acima deste balão a esquerda temos um novo balão que dentro dele temos o símbolo vazado que se refere a um coração. A direita deste balão e ainda acima do primeiro temos outro balão e em seu interior temos o traçado de uma mão fechada e levantanda, com o polegar elevado para cima, indicando um joinha(ou curtir)',
    title: 'Comunidade',
    context:
      'Você pode receber dicas de especialistas em marketing e vendas sobre como alavancar seu negócio e a melhor forma de aplicar os dados através de um grupo no Telegram e uma newsletter mensal.',
  },
];
export const plansForPlansSessionProps = [
  {
    personalizedService: false,
    title: 'Early',
    titleColor: 'brown',
    userQuantity: 'Até 500 leads/mês',
    firstButtonType: 'onClick',
    firstButtonOnClick: () => scrollTo('#lead-form'),
    firstBtnText: 'Assine agora',
    firstBtnBackgroundColor: 'brown',
    firstBtnBackgroundColorHover: 'brown',
    firstBtnBorderColor: 'brown',
    firstBtnBorderColorHover: 'brown',
    firstBtnTextColor: 'secondary',
    firstBtnTextColorHover: 'secondary',
    firstButtonRedirect: '/lead-form',
    listOfAdvantages: {
      checkColor: 'brown',
      list: [
        'Todas as integrações em todos os boards',
        'Integração com marketing',
      ],
    },
  },
  {
    personalizedService: false,
    title: 'Medium',
    titleColor: 'sixth',
    userQuantity: 'Até 2000 leads/mês',
    firstButtonType: 'onClick',
    firstButtonOnClick: () => scrollTo('#lead-form'),
    firstBtnBackgroundColor: 'fourth',
    firstBtnBackgroundColorHover: 'fourth',
    firstBtnBorderColor: 'fourth',
    firstBtnBorderColorHover: 'fourth',
    firstBtnText: 'Assine agora',
    firstBtnTextColor: 'secondary',
    firstBtnTextColorHover: 'secondary',
    firstButtonRedirect: '/lead-form',
    listOfAdvantages: {
      checkColor: 'fourth',
      list: [
        'Todas as integrações em todos os boards',
        'Integração com marketing e vendas',
      ],
    },
  },
  {
    personalizedService: false,
    title: 'Advanced',
    titleColor: 'third',
    userQuantity: 'Até 7000 leads/mês',
    firstButtonType: 'onClick',
    firstButtonOnClick: () => scrollTo('#lead-form'),
    firstBtnBackgroundColor: 'primary',
    firstBtnBackgroundColorHover: 'primary',
    firstBtnBorderColor: 'primary',
    firstBtnBorderColorHover: 'primary',
    firstBtnText: 'Assine agora',
    firstBtnTextColor: 'secondary',
    firstBtnTextColorHover: 'secondary',
    firstButtonRedirect: '/lead-form',
    listOfAdvantages: {
      checkColor: 'primary',
      list: [
        'Todas as integrações em todos os boards',
        'Integração com marketing e vendas',
      ],
    },
  },
  {
    personalizedService: true,
    title: 'Enterprise',
    titleColor: 'darkBrown',
    userQuantity:
      'Atendimento personalizado para empresas que geram mais de 7000 leads/mês Análise da necessidade de integrar outras aplicações',
    firstButtonType: 'onClick',
    firstButtonOnClick: () => scrollTo('#lead-form'),
    firstBtnBackgroundColor: 'darkBrown',
    firstBtnBackgroundColorHover: 'darkBrown',
    firstBtnBorderColor: 'darkBrown',
    firstBtnBorderColorHover: 'darkBrown',
    firstBtnText: 'Fale com um especialista',
    firstBtnTextColor: 'secondary',
    firstBtnTextColorHover: 'secondary',
    firstButtonRedirect: '/lead-form',
  },
];
export const footerSocialProp = [
  {
    imgSrc: Linkedin,
    imgAlt:
      'Quadrado azul com as letras in vazadas representando a rede social LinkedIn',
    redirect: 'https://www.linkedin.com/company/bowe-td/mycompany/',
  },
  {
    imgSrc: Facebook,
    imgAlt:
      'Circulo azul com a letra f vazada representando a rede social Facebook',
    redirect: 'https://www.facebook.com/bowe.td/',
  },
  {
    imgSrc: Instagram,
    imgAlt:
      'Simbolo de camera vazado em cor de degrade de rosa acima mudando para alaranjado representando a rede social Instagram',
    redirect: 'https://www.instagram.com/bowe.td/',
  },
];
